import hillo from 'hillo'
import { cloudUrl } from '@/model/dataLayer/api/SaaS/subscriptionService'

export async function creatTse (deviceId) {
  return (await hillo.jsonPost(cloudUrl + '/virtualDevice/createVirtualTse', {
    virtualDeviceDeviceId: deviceId
  }))
}

export async function searchVirtualTse (deviceId) {
  try {
    return (await hillo.jsonPost(cloudUrl + '/virtualDevice/searchVirtualTse', {
      deviceId
    })).data
  } catch (e) {
    return null
  }
}

export async function openAadenTse () {
  return (await hillo.post('Complex.php?op=enableTse', {}))
}
